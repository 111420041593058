// invoiceApi.js
import axios from 'axios';

const createInvoiceApi = (token) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Or just process.env.REACT_APP_API_URL if your proxy is set up that way
    headers: {
      authorization: token,
    },
  });

  return {
    /**
     * =============================
     * INVOICES
     * =============================
     */

    // 1) Get all invoices (minimal details)
    getAllInvoices: async () => {
      try {
        const response = await api.get('/invoices');
        console.log('[getAllInvoices] Success:', response.data);
        return response.data;
      } catch (error) {
        console.error('[getAllInvoices] Error:', error.response?.data || error.message);
        throw error;
      }
    },

    // 2) Get single invoice (including its items)
    getInvoiceById: async (invoiceId) => {
      try {
        const response = await api.get(`/invoices/${invoiceId}`);
        console.log('[getInvoiceById] Success:', response.data);
        return response.data;
      } catch (error) {
        console.error('[getInvoiceById] Error:', error.response?.data || error.message);
        throw error;
      }
    },

    // 3) Update an invoice
    // Allowed fields: vendorid, locationid, invoicedate, invoice_number, invoice_status
    updateInvoice: async (invoiceId, updateData) => {
      try {
        const response = await api.patch(`/invoices/${invoiceId}`, updateData);
        console.log('[updateInvoice] Success:', response.data);
        return response.data;
      } catch (error) {
        console.error('[updateInvoice] Error:', error.response?.data || error.message);
        throw error;
      }
    },

    // 4) Delete an invoice
    deleteInvoice: async (invoiceId) => {
      try {
        const response = await api.delete(`/invoices/${invoiceId}`);
        console.log('[deleteInvoice] Success:', response.data);
        return response.data;
      } catch (error) {
        console.error('[deleteInvoice] Error:', error.response?.data || error.message);
        throw error;
      }
    },

    /**
     * =============================
     * INVOICE ITEMS
     * =============================
     */

    // 5) Get all items from a specific invoice
    getInvoiceItems: async (invoiceId) => {
      try {
        const response = await api.get(`/invoices/${invoiceId}/items`);
        console.log('[getInvoiceItems] Success:', response.data);
        return response.data;
      } catch (error) {
        console.error('[getInvoiceItems] Error:', error.response?.data || error.message);
        throw error;
      }
    },

    // 6) Get a single item by its ID for a given invoice
    getInvoiceItemById: async (invoiceId, itemId) => {
      try {
        const response = await api.get(`/invoices/${invoiceId}/items/${itemId}`);
        console.log('[getInvoiceItemById] Success:', response.data);
        return response.data;
      } catch (error) {
        console.error('[getInvoiceItemById] Error:', error.response?.data || error.message);
        throw error;
      }
    },

    // 7) Create a new item for an invoice
    createInvoiceItem: async (invoiceId, newItemData) => {
      /*
        Expected newItemData fields:
        {
          description: string,
          quantity?: number,
          unitprice?: number,
          totalprice?: number,
        }
      */
      try {
        const response = await api.post(`/invoices/${invoiceId}/items`, newItemData);
        console.log('[createInvoiceItem] Success:', response.data);
        return response.data;
      } catch (error) {
        console.error('[createInvoiceItem] Error:', error.response?.data || error.message);
        throw error;
      }
    },

    // 8) Update an existing item for a given invoice
    updateInvoiceItem: async (invoiceId, itemId, updatedItemData) => {
      /*
        Possible updatedItemData fields:
        {
          description?: string,
          quantity?: number,
          unitprice?: number,
          totalprice?: number
        }
      */
      try {
        const response = await api.patch(
          `/invoices/${invoiceId}/items/${itemId}`,
          updatedItemData
        );
        console.log('[updateInvoiceItem] Success:', response.data);
        return response.data;
      } catch (error) {
        console.error('[updateInvoiceItem] Error:', error.response?.data || error.message);
        throw error;
      }
    },

    // 9) Delete a single item from a given invoice
    deleteInvoiceItem: async (invoiceId, itemId) => {
      try {
        const response = await api.delete(`/invoices/${invoiceId}/items/${itemId}`);
        console.log('[deleteInvoiceItem] Success:', response.data);
        return response.data;
      } catch (error) {
        console.error('[deleteInvoiceItem] Error:', error.response?.data || error.message);
        throw error;
      }
    },
  };
};

export default createInvoiceApi;
