// src/components/Orders/VendorsList.jsx
import React from 'react';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';

const VendorsList = ({ vendors, selectedVendor, onSelectVendor }) => {
  if (!vendors || vendors.length === 0) {
    return <p>No vendors found.</p>;
  }

  return (
    <List>
      {vendors.map((vendor) => {
        const isSelected = vendor.id === selectedVendor;
        return (
          <ListItem key={vendor.id} disablePadding>
            <ListItemButton selected={isSelected} onClick={() => onSelectVendor(vendor.id)}>
              <ListItemText primary={vendor.vendorname} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default VendorsList;
