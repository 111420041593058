// src/components/Orders/OrdersLandingPage.jsx
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';

import { useAuth } from '../../context/AuthContext';
import createInventoryApi from '../../components/Invoices/inventoryApi';

// Sub-components
import OrdersList from './OrdersList';             // Renders a list of Orders (Invoices)
import VendorsList from './VendorsList';           // Renders a list of Vendors
import VendorProductsList from './VendorProductsList'; // Renders a list of Vendor Products
import CreateOrderForm from './CreateOrderForm';

const OrdersLandingPage = () => {
  // ----------------------------------------------------------------
  //  1) Auth & API
  // ----------------------------------------------------------------
  const { currentUser } = useAuth();

  // Create the API instance once per token change
  const inventoryApi = useMemo(() => {
    const token = currentUser?.stsTokenManager?.accessToken;
    console.log('[OrdersLandingPage] Initializing API with token:', token);
    return createInventoryApi(token);
  }, [currentUser?.stsTokenManager?.accessToken]);

  // ----------------------------------------------------------------
  //  2) State: Vendors, Vendor Products, etc.
  // ----------------------------------------------------------------
  const [vendors, setVendors] = useState([]);         // All vendors
  const [vendorProducts, setVendorProducts] = useState([]); // Vendor products for the selected vendor

  const [selectedVendor, setSelectedVendor] = useState('');
  
  // For optional Orders fetching
  const [orders, setOrders] = useState([]);

  // Loading & Error states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Show/hide the "Create Order" dialog
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  // ----------------------------------------------------------------
  //  3) Fetch Vendors on mount
  // ----------------------------------------------------------------
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        setIsLoading(true);
        setError(null);

        console.log('[OrdersLandingPage] Fetching all vendors...');
        const res = await inventoryApi.getAllVendors();

        // For example, your vendor objects have { vendorid, vendorname, ... }
        // Let's map them to a simpler shape if we like:
        const mappedVendors = (res.data || []).map((v) => ({
          id: v.vendorid,
          name: v.vendorname,
          address: v.address,
        }));

        setVendors(mappedVendors);
        console.log('[OrdersLandingPage] Vendors fetched:', mappedVendors);

      } catch (err) {
        console.error('[OrdersLandingPage] Error fetching vendors:', err);
        setError(err.message || 'Failed to fetch vendors');
      } finally {
        setIsLoading(false);
      }
    };

    // Call fetch on mount
    fetchVendors();
  }, [inventoryApi]);

  // ----------------------------------------------------------------
  //  4) (Optional) Fetch Orders on mount
  // ----------------------------------------------------------------
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsLoading(true);
        setError(null);

        console.log('[OrdersLandingPage] Fetching all invoices (orders)...');
        const res = await inventoryApi.getAllInvoices();

        setOrders(res.data || []);
        console.log('[OrdersLandingPage] Orders fetched:', res.data);

      } catch (err) {
        console.error('[OrdersLandingPage] Error fetching orders:', err);
        setError(err.message || 'Failed to fetch orders');
      } finally {
        setIsLoading(false);
      }
    };

    // Only do this if your /invoices endpoint is valid
    fetchOrders();
  }, [inventoryApi]);

  // ----------------------------------------------------------------
  //  5) Fetch Vendor Products (when selectedVendor changes)
  // ----------------------------------------------------------------
  useEffect(() => {
    const fetchVendorProducts = async () => {
      if (!selectedVendor) {
        console.log('[OrdersLandingPage] No vendor selected, skipping fetch...');
        setVendorProducts([]);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);

        console.log(`[OrdersLandingPage] Fetching products for vendor ${selectedVendor}...`);
        const res = await inventoryApi.getVendorProductsForVendor(selectedVendor);

        // The returned data might be shaped like { id, vendorname, cost } 
        // or something else. We'll store it as-is:
        setVendorProducts(res.data || []);
        console.log(`[OrdersLandingPage] Products for vendor ${selectedVendor}:`, res.data);

      } catch (err) {
        console.error('[OrdersLandingPage] Error fetching vendor products:', err);
        setError(err.message || 'Failed to fetch vendor products');
      } finally {
        setIsLoading(false);
      }
    };

    fetchVendorProducts();
  }, [selectedVendor, inventoryApi]);

  // ----------------------------------------------------------------
  //  6) Handlers
  // ----------------------------------------------------------------
  const handleSelectVendor = (vendorId) => {
    console.log('[OrdersLandingPage] Vendor selected:', vendorId);
    setSelectedVendor(vendorId);
  };

  const handleCreateNewOrder = () => {
    console.log('[OrdersLandingPage] Opening "Create Order" dialog...');
    setShowCreateDialog(true);
  };

  // Called when a new order is successfully created
  const handleOrderCreated = (newOrder) => {
    console.log('[OrdersLandingPage] Order created successfully:', newOrder);
    // Optionally push the new order into our Orders list
    setOrders((prev) => [...prev, newOrder]);
  };

  // ----------------------------------------------------------------
  //  7) Render
  // ----------------------------------------------------------------
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Orders Landing Page
      </Typography>

      {/* Create New Order Button */}
      <Button
        variant="contained"
        sx={{ mb: 2 }}
        onClick={handleCreateNewOrder}
      >
        Create New Order
      </Button>

      {/* Loading / Error */}
      {isLoading && (
        <Box sx={{ mb: 2 }}>
          <CircularProgress /> <span>Loading...</span>
        </Box>
      )}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* 
        Layout: 3 columns
        1) Orders
        2) Vendors
        3) Vendor Products
      */}
      <Grid container spacing={3}>
        {/* Column 1: Orders */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Orders
            </Typography>
            <OrdersList orders={orders} />
          </Paper>
        </Grid>

        {/* Column 2: Vendors */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Vendors
            </Typography>
            <VendorsList
              vendors={vendors}
              selectedVendor={selectedVendor}
              onSelectVendor={handleSelectVendor}
            />
          </Paper>
        </Grid>

        {/* Column 3: Vendor Products */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Vendor Products
            </Typography>

            {/* Optional second dropdown for vendor selection */}
            <FormControl size="small" sx={{ mb: 2, minWidth: 120 }}>
              <InputLabel>Vendor</InputLabel>
              <Select
                value={selectedVendor}
                label="Vendor"
                onChange={(e) => handleSelectVendor(e.target.value)}
              >
                {vendors.map((v) => (
                  <MenuItem key={v.id} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <VendorProductsList vendorProducts={vendorProducts} />
          </Paper>
        </Grid>
      </Grid>

      {/* Create New Order Dialog */}
      <Dialog
        open={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create a New Order</DialogTitle>
        <DialogContent dividers>
          <CreateOrderForm
            token={currentUser?.stsTokenManager?.accessToken}
            onClose={() => setShowCreateDialog(false)}
            onSuccess={(createdInvoice) => {
              handleOrderCreated(createdInvoice);
              setShowCreateDialog(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default OrdersLandingPage;
