// src/components/Orders/CreateOrderForm.jsx

import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import createInventoryApi from '../../components/Invoices/inventoryApi';

const CreateOrderForm = ({ token, onClose, onSuccess }) => {
  console.log('[CreateOrderForm] Rendered. Token:', token);
  // Create an instance of your inventory API using the real token
  const api = useMemo(
    () => {
      return createInventoryApi(token);
    },
    []
  );

  // Basic invoice fields
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');

  // Vendors
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');

  // Vendor products for the selected vendor
  const [vendorProducts, setVendorProducts] = useState([]);
  const [lineItems, setLineItems] = useState([]); // { vendorProductId, quantity, cost, etc. }

  // Fetch vendors on mount
  useEffect(() => {
    console.log('[CreateOrderForm] Fetching all vendors...');
    api.getAllVendors()
      .then((res) => {
        console.log('[CreateOrderForm] Vendors fetched:', res.data);
        setVendors(res.data);
      })
      .catch((err) => console.error('[CreateOrderForm] Error fetching vendors:', err));
  }, [api]);

  // Fetch vendor products whenever selectedVendor changes
  useEffect(() => {
    if (!selectedVendor) {
      console.log('[CreateOrderForm] No vendor selected; clearing product lists');
      setVendorProducts([]);
      setLineItems([]);
      return;
    }
    console.log('[CreateOrderForm] Fetching vendor-products for:', selectedVendor);
    api.getVendorProductsForVendor(selectedVendor)
      .then((res) => {
        console.log(`[CreateOrderForm] Vendor products for ${selectedVendor}:`, res.data);
        setVendorProducts(res.data);
        // Initialize line items with zero quantity
        const initialLineItems = res.data.map((vp) => ({
          vendorProductId: vp.id,
          productName: vp.name,
          quantity: 0,
          cost: vp.cost || 0,
        }));
        setLineItems(initialLineItems);
      })
      .catch((err) => console.error('[CreateOrderForm] Error fetching vendor-products:', err));
  }, [selectedVendor, api]);

  // Handle quantity change
  const handleQuantityChange = (vendorProductId, newQty) => {
    console.log('[CreateOrderForm] Updating quantity for:', vendorProductId, '=>', newQty);
    setLineItems((prev) =>
      prev.map((item) =>
        item.vendorProductId === vendorProductId
          ? { ...item, quantity: newQty }
          : item
      )
    );
  };

  // Compute total
  const totalCost = lineItems.reduce(
    (sum, item) => sum + item.quantity * item.cost,
    0
  );

  // Submit
  const handleSubmit = () => {
    const invoiceData = {
      invoiceNumber,
      invoiceDate,
      vendorId: selectedVendor,
      lineItems: lineItems
        .filter((i) => i.quantity > 0)
        .map((i) => ({
          vendorProductId: i.vendorProductId,
          quantity: i.quantity,
          cost: i.cost,
        })),
      totalCost,
    };

    console.log('[CreateOrderForm] Submitting new invoice data:', invoiceData);

    api
      .createInvoice(invoiceData)
      .then((res) => {
        console.log('[CreateOrderForm] Invoice created successfully:', res.data);
        if (onSuccess) onSuccess(res.data);
      })
      .catch((err) => console.error('[CreateOrderForm] Failed to create invoice:', err));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Create New Order
      </Typography>

      <Stack spacing={2}>
        <TextField
          label="Invoice Number"
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
          size="small"
        />
        <TextField
          label="Invoice Date"
          type="date"
          value={invoiceDate}
          onChange={(e) => setInvoiceDate(e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />

        <FormControl size="small">
          <InputLabel>Vendor</InputLabel>
          <Select
            value={selectedVendor}
            label="Vendor"
            onChange={(e) => {
              console.log('[CreateOrderForm] Vendor selected:', e.target.value);
              setSelectedVendor(e.target.value);
            }}
          >
            {vendors.map((v) => (
              <MenuItem key={v.id} value={v.id}>
                {v.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Show products for the selected vendor */}
        {vendorProducts.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Vendor Products</Typography>
            {lineItems.map((item) => (
              <Stack
                key={item.vendorProductId}
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ my: 1 }}
              >
                <Typography sx={{ width: 150 }}>{item.productName}</Typography>
                <TextField
                  label="Quantity"
                  type="number"
                  size="small"
                  sx={{ width: 90 }}
                  inputProps={{ min: 0 }}
                  value={item.quantity}
                  onChange={(e) =>
                    handleQuantityChange(
                      item.vendorProductId,
                      parseInt(e.target.value, 10) || 0
                    )
                  }
                />
                <Typography>${item.cost.toFixed(2)} ea.</Typography>
              </Stack>
            ))}
          </Box>
        )}

        <Typography variant="h6">Total: ${totalCost.toFixed(2)}</Typography>

        {/* Actions */}
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => {
              console.log('[CreateOrderForm] Cancel clicked');
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Create
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CreateOrderForm;
