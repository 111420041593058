// src/components/Orders/VendorProductsList.jsx
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

const VendorProductsList = ({ vendorProducts }) => {
  if (!vendorProducts || vendorProducts.length === 0) {
    return <p>No vendor products for this vendor.</p>;
  }

  return (
    <List>
      {vendorProducts.map((prod) => (
        <ListItem key={prod.id}>
          <ListItemText
            primary={prod.vendorname}
            secondary={`Cost: $${prod.cost || '??'}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default VendorProductsList;
