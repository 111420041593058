import React, { useEffect, useMemo, useCallback } from 'react';
import { useAuth } from '../../context/AuthContext';
import createInvoiceApi from './newApi';

function MyInvoices() {
  const { currentUser } = useAuth();
  const token = currentUser?.stsTokenManager?.accessToken || '';

  // Memoize the API instance so it's not recreated on every render
  const invoiceApi = useMemo(() => createInvoiceApi(token), [token]);

  // Memoize the data-fetching function
  const fetchData = useCallback(async () => {
    try {
      const allInvoices = await invoiceApi.getAllInvoices();
      console.log('All invoices from API:', allInvoices);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  }, [invoiceApi]);

  // Fetch data once on mount (or when fetchData reference changes)
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return <div>My Invoices Component</div>;
}

export default MyInvoices;
