// src/components/Orders/OrdersList.jsx
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

const OrdersList = ({ orders }) => {
  console.log('[OrdersList] Rendering with orders:', orders);

  if (!orders || orders.length === 0) {
    return <p>No orders found.</p>;
  }

  return (
    <List>
      {orders.map((order) => (
        <ListItem key={order.id}>
          <ListItemText
            primary={`Order #${order.id}`}
            secondary={`Total: $${order.totalCost || '??'}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default OrdersList;
